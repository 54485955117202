import Link from "next/link";
import { VFC } from "react";

interface FooterProps {}

export const Footer: VFC<FooterProps> = ({}) => {
  return (
    <footer className="l-footer">
      <div className="l-footer__inner">
        <div className="p-ft">
          <p className="p-ft__logo">
            <img
              src="/assets/img/common/logo-wt.svg"
              alt="地域・教育魅力化プラットフォーム"
            />
          </p>
          <p className="p-ft__catch">みらいを、その手に。</p>
          <p className="p-ft__ttl">
            一般財団法人 <br className="u-sp" />
            地域・教育魅力化プラットフォーム
          </p>
          <p className="p-ft__addr">
            〒690-0886島根県松江市母衣町83番地5 <br className="u-sp" />
            母衣町ビル3階
            <br />
            TEL 0852-61-8866／FAX 0852-61-8867
          </p>
          <p className="c-btn -ft">
            <a
              href="https://c-platform.or.jp/contact/"
              target="_blank"
              className="u-over"
              rel="noreferrer"
            >
              お問い合わせ
            </a>
          </p>
          <p className="p-ft__sns">
            <a
              href="https://www.facebook.com/c.platform.or.jp/"
              target="_blank"
              className="u-over"
              rel="noreferrer"
            >
              <img src="/assets/img/common/icon-fb-wt.svg" alt="Facebook" />
            </a>
            <a href="" className="u-over">
              <img src="/assets/img/common/icon-ig-wt.svg" alt="Instagram" />
            </a>
            <a
              href="https://twitter.com/platform_sec"
              target="_blank"
              className="u-over"
              rel="noreferrer"
            >
              <img src="/assets/img/common/icon-tw-wt.svg" alt="Twitter" />
            </a>
          </p>
          <p className="p-ft__copy">
            <small>
              ©2017 地域・教育魅力化プラットフォーム All rights reserved.
            </small>
          </p>
        </div>
      </div>
    </footer>
  );
};
