import Head from "next/head";
import { ReactNode, VFC } from "react";
import { ToastContainer } from "react-toastify";
import { Footer } from "./Footer";
import { Header } from "./Header";

interface Props {
  children: ReactNode;
  title: string;
}

export const Layout: VFC<Props> = ({
  children,
  title = "地域・教育魅力化プラットフォーム",
}) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TGN7WSK');
          `,
          }}
        />
      </Head>
      <div className="l-body">
        <ToastContainer />
        <Header />
        <main className="l-main">
          <div className="l-main__inner">{children} </div>
        </main>
        <Footer />
      </div>
    </>
  );
};
