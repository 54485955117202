import { useUser } from "@auth0/nextjs-auth0";
import Link from "next/link";
import { VFC } from "react";

interface HeaderProps {}

export const Header: VFC<HeaderProps> = ({}) => {
  const { user, error, isLoading } = useUser();
  return (
    <div className="l-header__wrap">
      <header className="l-header">
        <div className="l-header__inner">
          <div className="p-hd">
            <div className="p-hd__cols">
              <div className="p-hd__logo">
                <h1 className="p-hd__logoImg">
                  <a href="/">
                    <img
                      src="/assets/img/common/logo.svg"
                      alt="地域・教育魅力化プラットフォーム"
                    />
                  </a>
                </h1>
              </div>
              <div className="p-hd__navs">
                <nav className="p-gnav">
                  <div className="p-gnav__inner">
                    <ul>
                      <li>
                        <a
                          href="https://c-platform.or.jp/#section_activity"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>理念</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://c-platform.or.jp/#section_education"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>活動内容</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://c-platform.or.jp/#section_method"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>沿革</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://c-platform.or.jp/#section_member"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>メンバー</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://supporter.c-platform.or.jp/?_ga=2.12492404.1796144757.1625725492-304828565.1621469671"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>ご寄付</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://c-platform-or-jp.webnode.jp/%20%E6%B1%82%E4%BA%BA%E6%83%85%E5%A0%B1%EF%BC%92/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>採用</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://c-platform.or.jp/#section_map"
                          target="_blank"
                          rel="nore noreferrer"
                        >
                          <span>所在地</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-gnav__spInner">
                    <p className="c-btn -hd">
                      <a href="/mypage/history" className="u-over">
                        過去に寄付された方はこちら
                      </a>
                    </p>
                    {/* <p className="c-btn -hd -gy"><a href="" className="u-over">ログアウト</a></p> */}
                    <div className="p-gnav__sns2">
                      <a
                        href="https://www.facebook.com/c.platform.or.jp/"
                        target="_blank"
                        className="u-over"
                        rel="noreferrer"
                      >
                        <img
                          src="/assets/img/common/icon-fb.png"
                          alt="Facebook"
                        />
                      </a>
                      <a
                        href="https://twitter.com/platform_sec"
                        target="_blank"
                        className="u-over"
                        rel="noreferrer"
                      >
                        <img
                          src="/assets/img/common/icon-tw.png"
                          alt="Twitter"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="p-gnav__menu2">
                    <span></span>
                  </div>
                </nav>
                <div className="p-hd__sns">
                  <a href="" className="u-over">
                    <img src="/assets/img/common/icon-fb.png" alt="Facebook" />
                  </a>
                  <a href="" className="u-over">
                    <img src="/assets/img/common/icon-tw.png" alt="Twitter" />
                  </a>
                </div>
                <div className="p-hd__btn">
                  {user ? (
                    <p className="c-btn -hd -gy">
                      <a href="/api/auth/logout" className="u-over">
                        ログアウト
                      </a>
                    </p>
                  ) : (
                    <p className="c-btn -hd">
                      <a href="/mypage/history" className="u-over">
                        過去に寄付された方はこちら
                      </a>
                    </p>
                  )}
                </div>
                <div className="p-hd__menu">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
